import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import banksy from "../../banksy";

interface Config {
  amount: number;
  currency: string;
}

const Contract: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [config, setConfig] = useState<Config>({ amount: 0, currency: "" });
  const [contracts, setContracts] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contractsData = await banksy.getContracts();
        console.log("🚀 ----------------------------------------------🚀");
        console.log(
          "🚀 ~ fetchData ~ contractsData:",
          contractsData.ETH_SEPOLIA
        );
        console.log("🚀 ----------------------------------------------🚀");
        setContracts(contractsData.ETH_SEPOLIA);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (location.state) {
      setConfig(location.state as Config);
    }
  }, [location]);

  const onContract = async (contract: any) => {
    try {
      console.log("🚀 -------------------------------------🚀");
      console.log("🚀 ~ onContract ~ contract:", contract);
      console.log("🚀 -------------------------------------🚀");
      const paymentData = await banksy.createPayment({
        currencyType: "crypto",
        amount: config.amount,
        currency: config.currency,
        successCallback: "http://localhost:3000/success",
        failureCallback: "http://localhost:3000/failure",
        externalClientId: "cd99d68f-db08-485a-ac74-b8b64a55a3c2",
        isKycOptional: true,
        customerEmail: "dev@banksy.id",
        crypto: {
          tokenName: contract.name,
          blockchainSymbol: contract.symbol,
        },
      });
      window.location.assign(paymentData.paymentLink);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="col-12 products-wrap provider">
      <h1 className="title border-rounded mt-1 dark">Provider</h1>
      <ul className="mt-1 ms-1 me-1 border border-rounded">
        {contracts.map((contract: any, index: number) => (
          <li key={index} onClick={() => onContract(contract)}>
            <img src={contract.logo} alt={contract.name} height={30} />
            <span>
              {contract.name}
              <small>({contract.symbol})</small>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contract;
