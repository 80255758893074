import React from "react";
import { useNavigate } from "react-router-dom";

const product = [
  // {
  //   id: 1,
  //   title: "Product 1",
  //   amount: 0.66,
  //   currency: "usd",
  //   image:
  //     "https://banksy.id/wp-content/uploads/2024/04/BANKSY-Digital-asset-verification-and-certification-i08-ds-sx2132.jpg",
  // },
  {
    id: 2,
    title: "Product 1",
    amount: 1,
    currency: "usd",
    image:
      "https://banksy.id/wp-content/uploads/2024/04/BANKSY-Digital-asset-verification-and-certification-nbds-sx2132.jpg",
  },
  {
    id: 2,
    title: "Product 2",
    amount: 100,
    currency: "inr",
    image:
      "https://banksy.id/wp-content/uploads/2024/04/BANKSY-Digital-asset-verification-and-certification-i08-ds-sx2132.jpg",
  },
  {
    id: 3,
    title: "Product 3",
    amount: 100,
    currency: "brl",
    image: "https://minimalistic-wallpaper.demolab.com/?random=3",
  },
  {
    id: 4,
    title: "Product 4",
    amount: 100,
    currency: "aed",
    image: "https://minimalistic-wallpaper.demolab.com/?random=4",
  },
];

const Product: React.FC = () => {
  const navigate = useNavigate();

  const onBuy = (item: any) => {
    navigate("/provider", { state: item });
  };

  const onCrypto = () => {
    window.location.assign("http://192.168.0.151:3000/crypto");
  };

  return (
    <div className="col-12 products-wrap products">
      <h1 className="title border-rounded mt-1 dark">Product</h1>
      <button onClick={() => onCrypto()} className="mt-1">
        Fund wallet using crypto
      </button>
      <ul className="mt-1 ms-1 me-1">
        {product.map((item) => (
          <li key={item.id} className="border border-rounded pb-1 mb-1 product">
            <div className="product-img">
              <img src={item.image} alt="Product Name" />
            </div>
            <div className="product-info">
              <h4>{item.title}</h4>
              <div className="col-12 price">
                {item.amount}
                <span className="currency">{item.currency.toUpperCase()} </span>
              </div>
            </div>
            <button onClick={() => onBuy(item)}>Buy Now</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Product;
