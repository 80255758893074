import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Product from "../screen/product";
import Provider from "../screen/provider";
import Success from "../screen/success";
import Failure from "../screen/failure";
import Contract from "../screen/contract";

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/provider" element={<Provider />} />
        <Route path="/contracts" element={<Contract />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
