import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import banksy from "../../banksy";

const Failure: React.FC = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const searchObject: Record<string, string> = {};
        const searchParams = new URLSearchParams(location.search);
        Array.from(searchParams.entries()).forEach(([key, value]) => {
          searchObject[key] = value;
        });
        const paymentData = await banksy.getPayment(searchObject.paymentId);
        if (paymentData.status !== "success") {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [location.search]);

  return (
    <div className="col-12 products-wrap products">
      <h1 className="title border-rounded mt-1 mb-1 dark">Status</h1>
      {loading ? (
        <p className="fetching border-rounded ms-1 me-1 p-1">Please wait fetching payment status</p>
      ) : (
        <p className="failure border-rounded ms-1 me-1 p-1">Payment failed</p>
      )}
    </div>
  );
};

export default Failure;
