import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import banksy from "../../banksy";

interface Config {
  amount: number;
  currency: string;
}

const Provider: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [config, setConfig] = useState<Config>({ amount: 0, currency: "" });
  const [providers, setProviders] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const providersData = await banksy.getProviders();
        setProviders(providersData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (location.state) {
      setConfig(location.state as Config);
    }
  }, [location]);

  const onProvider = async (provider: any) => {
    try {
      if (provider.name === "banksy") {
        navigate("/contracts", {
          state: {
            currencyType: "crypto",
            amount: config.amount,
            currency: config.currency,
            successCallback: "http://localhost:3000/success",
            failureCallback: "http://localhost:3000/failure",
            externalClientId: "cd99d68f-db08-485a-ac74-b8b64a55a3c2",
            isKycOptional: true,
            customerEmail: "dev@banksy.id",
          },
        });
      } else {
        const paymentData = await banksy.createPayment({
          currencyType: "fiat",
          amount: config.amount,
          currency: config.currency,
          successCallback: "http://localhost:3000/success",
          failureCallback: "http://localhost:3000/failure",
          externalClientId: "cd99d68f-db08-485a-ac74-b8b64a55a3c2",
          isKycOptional: true,
          customerEmail: "dev@banksy.id",
        });
        window.location.assign(paymentData.paymentLink);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="col-12 products-wrap provider">
      <h1 className="title border-rounded mt-1 dark">Provider</h1>
      <ul className="mt-1 ms-1 me-1 border border-rounded">
        {providers.map((provider: any) => (
          <li key={provider._id} onClick={() => onProvider(provider)}>
            <img src={provider.image} alt={provider.title} height={30} />
            {provider.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Provider;
